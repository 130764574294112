import * as React from "react";
import { graphql, Page, PageProps } from "gatsby";
import { Helmet } from "react-helmet";
import { usePreviewData } from "@topm/shared";
import { Layout } from "../components/layout";
import { ContentWrapper } from "../components/content-wrapper";

import { HeroImg } from "../components/hero-img";
import { HeroImgGuide } from "../components/hero-img-guide";

import { PrismicSliceFormatter } from "../components/prismic-slice-formatter";
import { TopPageQuery } from "../graphqlTypes";

export const query = graphql`
  query TopPage {
    prismicLpTopmFive {
      data {
        url
        title
        meta_description
        meta_keywords
        body {
          ... on PrismicLpTopmFiveBodyJsx {
            primary {
              src
            }
            slice_type
            id
          }
          ... on PrismicLpTopmFiveBodyHtml {
            primary {
              src
            }
            slice_type
            id
          }
          ... on PrismicLpTopmFiveBodyImgRichtext {
            primary {
              img_narrow {
                alt
                url
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid
                }
              }
              img_position
              img_wide {
                alt
                url
                fluid(maxWidth: 800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              richtext {
                html
              }
              ul_style
            }
            slice_type
            id
          }
          ... on PrismicLpTopmFiveBodyLetterBlock {
            primary {
              richtext {
                html
              }
            }
            slice_type
            id
          }
          ... on PrismicLpTopmFiveBodyRichtext {
            primary {
              richtext {
                html
              }
            }
            slice_type
            id
          }
          ... on PrismicLpTopmFiveBodyProfileBlock {
            primary {
              section1 {
                html
              }
              section2 {
                html
              }
              section3 {
                html
              }
            }
            slice_type
            id
          }
          ... on PrismicLpTopmFiveBodyImgJsx {
            primary {
              img_narrow {
                alt
                url
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid
                }
              }
              img_position
              img_wide {
                alt
                url
                fluid(maxWidth: 800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              jsx
            }
            slice_type
            id
          }
          ... on PrismicLpTopmFiveBodyTextSet {
            primary {
              richtext {
                html
              }
            }
            slice_type
            id
          }
          ... on PrismicLpTopmFiveBodyLrSplitRichtext {
            primary {
              richtext_left {
                html
              }
              richtext_right {
                html
              }
            }
            slice_type
            id
          }
        }
      }
    }
  }
`;

interface IIndexPageProps extends PageProps {
  data: TopPageQuery;
}
const IndexPage: React.FC<IIndexPageProps> = ({ data }) => {
  // merge liveData for preview
  const liveData = usePreviewData(data);

  const { url, body, title, meta_description, meta_keywords } =
    liveData.prismicLpTopmFive.data;

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="keywords" content={meta_keywords} />
        <meta name="description" content={meta_description} />
        <link rel="canonical" href={url} />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={meta_description} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={title} />
      </Helmet>
      <HeroImg
        srcHeroNarrow="https://images.prismic.io/takazudo-gatsby-example/4a8faa13-ba41-4ed5-882a-ae8f3fd9882a_hero1.png?auto=compress,format"
        srcHeroWide="https://images.prismic.io/takazudo-gatsby-example/14a4e25c-1378-4c90-bb7b-01753f77af55_hero2.png?auto=compress,format"
        alt="ドラッカー 5つの質問 オンラインセミナー 5つの取り組みで経営幹部は必ず育つ。一枚岩の経営チームを作る方法"
      />
      <HeroImgGuide />
      <div id="start" className="relative lg:top-vgap-xl"></div>
      <ContentWrapper className="pb-vgap-lg">
        {body.map((itemData) => (
          <PrismicSliceFormatter data={itemData} key={itemData.id} />
        ))}
      </ContentWrapper>
      <div className="h-[30vh]"></div>
    </Layout>
  );
};

export default IndexPage;
