import * as React from "react";
import { LetterBlock } from "@topm/shared";
import { Richtext } from "@topm/shared";
import { SplitVertically } from "@topm/shared";
import { TextSet } from "@topm/shared";
import { ImgTextBlock } from "@topm/shared";

import { ProfileBlock } from "./profile-block";
import { JsxFormatter } from "./jsx-formatter";

interface IPrismicSliceFormatter {
  data: {
    primary: any;
    slice_type: string;
  }
}

const PrismicSliceFormatter: React.FC<IPrismicSliceFormatter> = ({ data }) => {
  const root = data.primary;
  let result: React.ReactElement;
  switch (data.slice_type) {
    case "text-set":
      result = <TextSet html={root.richtext.html} />;
      break;
    case "img-richtext":
      result = (
        <ImgTextBlock
          imgWide={root.img_wide.url}
          imgNarrow={root.img_narrow.url}
          imgFluidWide={root.img_wide.fluid}
          imgFluidNarrow={root.img_narrow.fluid}
          imgPosition={
            root.img_position === "左テキスト右画像" ? "right" : "left"
          }
          ulStyle={root.ul_style === "太文字" ? "bigText" : "check"}
          html={root.richtext.html}
        />
      );
      break;
    case "img-jsx":
      result = (
        <ImgTextBlock
          imgWide={root.img_wide.url}
          imgNarrow={root.img_narrow.url}
          imgFluidWide={root.img_wide.fluid}
          imgFluidNarrow={root.img_narrow.fluid}
          imgPosition={
            root.img_position === "左テキスト右画像" ? "right" : "left"
          }
          ulStyle={root.ul_style === "太文字" ? "bigText" : "check"}
          jsx={<JsxFormatter jsx={root.jsx} />}
        />
      );
      break;
    case "html":
      result = <div dangerouslySetInnerHTML={{ __html: root.src }}></div>;
      break;
    case "jsx":
      result = <JsxFormatter jsx={root.src} />;
      break;
    case "letter-block":
      result = <LetterBlock html={root.richtext.html} />;
      break;
    case "lr-split-richtext":
      result = (
        <SplitVertically>
          <SplitVertically.Area>
            <Richtext html={root.richtext_left.html} />
          </SplitVertically.Area>
          <SplitVertically.Area>
            <Richtext html={root.richtext_right.html} />
          </SplitVertically.Area>
        </SplitVertically>
      );
      break;
    case "profile-block":
      result = (
        <ProfileBlock
          className="pt-vgap-lg pb-vgap-2xl"
          section1={root.section1.html}
          section2={root.section2.html}
          section3={root.section3.html}
        />
      );
      break;
    case "richtext":
      result = <Richtext html={root.richtext.html} />;
      break;
    default:
      console.error(`Unknown data type detected: ${data.slice_type}`);
      result = <></>;
  }
  return result;
};

export { PrismicSliceFormatter };
